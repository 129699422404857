<template>
  <section>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          :statistic="`${dataPeserta}`"
          statistic-title="Peserta"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ServerIcon"
          color="success"
          :statistic="`${dataDosen}`"
          statistic-title="Dosen"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ActivityIcon"
          color="danger"
          :statistic="`${dataArtikel}`"
          statistic-title="Artikel"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="warning"
          :statistic="`${dataProduct}`"
          statistic-title="Soal"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <b-card no-body class="card-developer-meetup">
          <div class="bg-light-primary rounded-top text-center">
            <b-img
              src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-2/img/email.de935b8f.svg"
              alt="Meeting Pic"
              height="170"
            />
          </div>
          <b-card-body>
            <!-- metting header -->
            <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0">Tgl</h6>
                <h3 class="mb-0">
                  {{ new Date().getDate() }}
                </h3>
              </div>
              <div class="my-auto">
                <b-card-title class="mb-25"> Hi, {{ nama }} </b-card-title>
                <b-card-text class="mb-0">
                  Kerjakan Ujian Sesuai dengan Jadwal
                </b-card-text>
              </div>
            </div>
            <!--/ metting header -->

            <!-- media -->
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon icon="BookmarkIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body v-if="level == 0">
                <h6 class="mb-0">
                  <b>FREE TRIAL</b>
                </h6>
                <small
                  >Silahkan Upgrade Akun, untuk lebih banyak keuntungan</small
                >
              </b-media-body>
              <b-media-body v-else-if="level == 1">
                <h6 class="mb-0">
                  <b>PREMIUM</b>
                </h6>
                <small>Akun ini sudah melakukan Upgrade Akun</small>
              </b-media-body>
              <b-media-body v-if="level == 2">
                <h6 class="mb-0">
                  <b>ADMINISTRATOR</b>
                </h6>
                <small>Akun ini dikelola oleh Internal Team</small>
              </b-media-body>
            </b-media>
            <br />
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon icon="MailIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  <b>{{email}}</b>
                </h6>
                <small
                  >Email yang digunakan untuk registrasi</small
                >
              </b-media-body>
            </b-media>
            <br />
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon icon="MapPinIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  <b>{{kampus}}</b>
                </h6>
                <small
                  >Kampus yang digunakan untuk registrasi</small
                >
              </b-media-body>
            </b-media>
            <br />
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  <b>{{nameMonth}}</b>
                </h6>
                <small
                  >Tanggal dan Waktu Saat Ini</small
                >
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col xl="4" md="6">
        <b-card>
          <b-card-header style="padding-top: 0px; padding-left: 0px">
            <div class="d-flex align-items-center">
              <b-avatar rounded variant="light-primary" size="34" class="mr-1">
                <feather-icon icon="ClockIcon" size="18" />
              </b-avatar>
              <b-card-title>
                <b style="color: darkslateblue">Informasi Jadwal Ujian</b>
              </b-card-title>
            </div>
          </b-card-header>
          <app-timeline>
            <app-timeline-item
              title="Hasil Ujian Gelombang 1"
              subtitle="Ujian Sudah Selesai"
              icon="CheckSquareIcon"
              variant="success"
              time="Selesai"
            />
            <app-timeline-item
              title="Hasil Ujian Gelombang 2"
              subtitle="Ujian Sudah Selesai"
              icon="CheckSquareIcon"
              variant="success"
              time="Selesai"
            />
            <app-timeline-item
              title="Hasil Ujian Gelombang 3"
              subtitle="Ujian Sedang Dilaksanakan"
              icon="ClockIcon"
              variant="warning"
              time="Pending"
            />
            <app-timeline-item
              title="Hasil Ujian Gelombang 4"
              subtitle="Belum Dijadwalkan"
              icon="ClockIcon"
              variant="danger"
              time="Tidak Ada"
            />
            <app-timeline-item
              title="Hasil Ujian Gelombang 5"
              subtitle="Belum Dijadwalkan"
              icon="AwardIcon"
              variant="danger"
              time="Tidak Ada"
            />
          </app-timeline>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BMedia,StatisticCardHorizontal,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      nama: localStorage.getItem("Unama"),
      email: localStorage.getItem("Uemail"),
      level: localStorage.getItem("Ulevel"),
      kampus: localStorage.getItem("Ukampus"),
      nameMonth: "",
      dataPeserta: [],
      dataDosen: [],
      dataArtikel: [],
      dataProduct: [],
      // VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL
    };
  },
  mounted() {
    this.getMonthName();
    this.getCountPeserta();
    this.getCountDosen();
    this.getCountArtikel();
    this.getCountProduct();
  },
  //   created() {
  //   this.getMonthName();
  // },
  methods: {
    getMonthName() {
      // const date = new Date(); // 2009-11-10
      // const month = date.toLocaleString("default", { month: "long" });
      // this.nameMonth = month;

      const arrbulan = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
      const arrhari = ["Minggu","Senin","Selasa","Rabu","Kamis","Jum'at","Sabtu"];
      var date = new Date();
      var millisecond = date.getMilliseconds();
      var detik = date.getSeconds();
      var menit = date.getMinutes();
      var jam = date.getHours();
      var hari = date.getDay();
      var tanggal = date.getDate();
      var bulan = date.getMonth();
      var tahun = date.getFullYear();
      var time = arrhari[hari] +", "+ tanggal +" "+ arrbulan[bulan] +" "+ tahun +", "+ jam +" : "+ menit + " : " + detik;
      this.nameMonth = time;
    },
    async getCountPeserta() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "users/count")
        .then((res) => {
          this.dataPeserta = res.data.data;
        });
    },
    async getCountDosen() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "teachers/count")
        .then((res) => {
          this.dataDosen = res.data.data;
        });
    },
    async getCountArtikel() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "blogs/count")
        .then((res) => {
          this.dataArtikel = res.data.data;
        });
    },
    async getCountProduct() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "products/count")
        .then((res) => {
          this.dataProduct = res.data.data;
        });
    },
  },
};
</script>
